<template>
    <div>
        <div class="my-input-container">
            <input 
                :id="myid" 
                type="password" 
                class="my-input"
                :placeholder="placeholder" 
                @keyup="publishEvent()" 
                v-model="value"
            />
            <span class="my-input-suffix" @click="changeEyeStatus">
                <span class="my-input-suffix-inner">
                    <i :class="eye" style="width: 25px; line-height: 40px;"></i>
                </span>
            </span>
        </div>

        <div id="count" style="padding: 5px; color: gray; font-size: small;">{{count}}</div>
    </div>
</template>

<script>
import EventBus from '../utils/eventBus'
export default {
    name : 'MyPasswordInput',
    props : {
        myid : { type : String, required : true },
        placeholder: { type : String, required : true }
    },

    data : function() {
        return {
            value : '',
            showValue : false
        }
    },

    created() {
        let self = this
        EventBus.on('reset', self.reset)
    },

    computed : {
        eye() {
            return this.showValue ? 'fa fa-eye-slash icon-style' : 'fa fa-eye icon-style'
        },

        count() {
            return `已输入: ${this.value.length}个字符`
        }
    },

    methods : {
        // 处理输入事件
        publishEvent() {
            let self = this
            self.$emit('myPasswordInputUpdated', { newVal : self.value })
        },

        // 处理单机“眼睛”icon事件
        changeEyeStatus() {
            var elem = document.getElementById(this.myid)
            if (this.showValue) {
                elem.type = 'password'
            } else {
                elem.type = 'text'
            }
            this.showValue = !this.showValue
        },

        reset() {
            this.value = ''
        }
    }
}
</script>

<style>
    .my-input-container {
        position: relative;
        font-size: 14px;
        display: inline-block;
    }

    .my-input {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: none;
        padding: 0 15px;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);
        width: 100%;
    }

    .my-input-suffix {
        position: absolute;
        height: 100%;
        right: 5px;
        top: 0;
        text-align: center;
        color: #c0c4cc;
        transition: all .3s;
        pointer-events: none;
    }

    .my-input-suffix-inner {
        pointer-events: all;
    }

    #myPasswordInput {
        width: 90%;
        border: 1px solid #ced4da;
        border-radius: .3rem;
        font-size: 16px;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    #icon-style {
        widows: 10%;
    }
</style>