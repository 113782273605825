<template>
    <div id="container">
        <div id="sub-header">输错5次当天将被锁定</div>
        <div id="password-group">
            <MyPassWordInput myid="loginPassword" placeholder="请输入您的密码" @myPasswordInputUpdated="updateUserInput"/>
        </div>
        <div id="warning"></div>
        <div id="btn-container">
            <button id="submit" @click="login" :disabled="disableLogin">查看工资</button>
            <button id="reset" @click="reset">清空</button>
        </div>
    </div>
</template>

<script>
import MyPassWordInput from '../components/MyPasswordInput.vue'
import EventBus from '../utils/eventBus'
import globalVariables from '../utils/globalVariables'
export default {
    name: 'Login',

    components : {
        MyPassWordInput
    },

    data: function() {
        return {
            password: '',
            jscode: ''
        }
    },

    mounted() {
        let code = this.$route.query.code

        if (code) {
            this.jscode = code
        } else {
            this.$router.replace('/error?reason=' + '未获得微信授权，无法查看工资单')
        }
    },

    computed: {
        disableLogin() {
            return this.password.trim() == ''
        }
    },

    methods: {
        login() {
            let encrypted = this.$md5(this.password)
            let payroll = localStorage.getItem('payroll')

            if (payroll && payroll != null) {
                let self = this
                const requestUrl = '/api/decryption/loginAndDecrypt'

                let payload = new FormData()
                payload.append('jsCode', self.jscode)
                payload.append('password', encrypted)
                payload.append('payroll', decodeURIComponent(payroll))

                self.$axios
                    .post(requestUrl, payload)
                    .then(res => {
                        if (res.data.status == 0) {
                            
                            let statusCode = res.data.data.status
                            switch(statusCode) {
                                case 200 :
                                    // 成功
                                    globalVariables.setContent(JSON.stringify(res.data.data.data))
                                    self.$router.replace(
                                        { 
                                            path : '/template', 
                                            query : {
                                                'name' : res.data.data.name,
                                                'mainDepartment' : res.data.data.mainDepartment
                                            }
                                        }
                                    )
                                    break
                                default : 
                                    // statusCode为其他值的时候均为错误
                                    self.errorPage(JSON.stringify(res.data.data.data))
                            }
                        } else {
                            // 失败
                            self.errorPage(JSON.stringify(res.data.data))
                        }
                    })
                    .catch(err => {
                        self.errorPage(JSON.stringify(err))
                    })
            }
        },

        reset() {
            EventBus.emit('reset')
            this.password = ''
        },

        // route到error页
        errorPage(msg) {
            this.$router.replace('/error?reason=' + msg)
        },

        updateUserInput(e) {
            this.password = e.newVal
        }
    }
}
</script>

<style scoped>
    #container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    #sub-header {
        color: gray;
        font-weight: 500;
        font-size: large;
        padding: 10px;
    }

    #btn-container {
        padding: 5px;
    }

    #submit {
        color: #fff;
        background-color: #42b983;
        border-color: #42b983;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
    }

    #reset {
        color: #fff;
        background-color: #409eff;
        border-color: #409eff;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 4px;
    }
</style>